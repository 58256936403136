import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <h1 className="text-center">404: Not Found</h1>
    <h2 className="text-center">Ouch. We know how it feels.</h2>

    <p className="text-center">We've recently changed our site so some pages may not be there. Feel free to browse our brand new website!</p>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
